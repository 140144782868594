.languages {
  display: flex;
  position: absolute;
  width: 100px;
  right: 25px;
  transition: 0.33s;
}

.visible {
  top: 7px;
  transition: top 0.4s ease-out;
}

.hidden {
  top: -80px;
  transition: top 0.4s ease-out;
}

.language {
  flex: 1;
  transition: 0.33s;

  img {
    filter: grayscale(75%);
  }
}

.active-language {
  flex: 1.5;

  img {
    filter: grayscale(0%);
  }
}
