$dark-green: #0e2121;
$poly-green: #14591d;
$apple-green: #99aa38;
$straw: #e1e289;
$uranian-blue: #acd2ed;
$white: #fff;
$grey-white: #f0f0f0;
$light-grey: #878787;
$dark-grey: #232323;
$grey: #4E4E4E;
$blue: #34c3ff;