@import "../../colors.scss";

.team {
  min-width: 768px;
  padding-left: 25px;
  padding-right: 25px;
  background-color: $white;

  .member {
    .first-name {
      text-transform: uppercase;
      font-weight: 600;
      font-size: large;
    }

    .last-name {
      text-transform: uppercase;
      font-weight: 400;
      font-size: large;
    }

    .role {
      font-weight: 400;
      font-size: small;
    }
  }

  .slick-track {
    .slick-slide {
      > div {
        &:first-child {
          padding-left: 12.5px;
          padding-right: 12.5px;
          padding-top: 25px;
          padding-bottom: 25px;
        }
      }
    }
  }
}
