.parallax {
  perspective: 100px;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 0;
  left: 50%;
  right: 0;
  bottom: 0;
  margin-left: -1500px;
}

.parallax__layer{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  
  img{
    display: block;
    position: absolute;
    bottom: 0;
  }
}

.parallax__cover{
  background: #acd2ed;
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 2;

  .parallax__content {
    width: 100vw; 
    left: calc(100% - 100vw);
    position: relative;
  }
}

$parallax__layers: 6;

@for $i from 0 through $parallax__layers {
  $x: ($parallax__layers - $i) / 2;
  .parallax__layer__#{$i}{
     transform: translateZ(-100 * $x + px) scale($x + 1);
  }
}