@import "../../colors.scss";

.team-description {
  background-color: $dark-green;

  .credo {
    flex: 1 1 250px;
    color: white;
    padding: 40px;

    h1 {
      padding-bottom: 5px;
    }

    .first-word {
      font-weight: 300;
      font-size: xx-large;
    }

    .second-word {
      font-size: xx-large;
    }

    .description {
      font-size: x-large;
      font-weight: 100;
    }
  }
}
