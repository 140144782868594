@import "../../colors.scss";

.team-contact {
  background-color: $dark-green;
  color: $white;
  padding: 40px;

  .name {
    text-transform: uppercase;
    font-weight: 700;
    font-size: larger;
  }

  .address, .phone {
    font-weight: 300;
    text-align: center;
    padding-top: 10px;
    font-size: medium;
  }

  .phone, .email {
    font-weight: 300;
    font-size: medium;
  }
}