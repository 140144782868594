.cover-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.player,
.video {
  height: 100%;
  width: 100%;
  cursor: pointer;
}
