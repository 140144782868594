.layout {
    margin: 0;
    padding: 0;
    align-items: center;
    min-height: 100vh;

    .logo {
        opacity: 0;
        animation: fadeIn 3s forwards;
    }

    .copyright {
        opacity: 0;
        text-align: center;
        margin: 20px;
        animation: fadeIn 4s forwards;

        .name {
            font-size: large;
            font-weight: bold;
        }

        .contact {
            font-weight: 600;
        }
    }
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}