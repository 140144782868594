@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import "colors.scss";

* {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  font-size: xx-large;
  font-weight: 500;
  padding-bottom: 15px;
  color: $dark-grey;
}

h3 {
  padding-bottom: 10px;
}

h5 {
  font-weight: 200;
  color: $light-grey;
  padding-bottom: 5px;
}

p {
  font-size: medium;
  font-weight: 400;
  padding-bottom: 10px;
  color: $grey;
}
