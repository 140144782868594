@import "colors.scss";

* {
  box-sizing: border-box;
}

html,
body {
  min-width: 350px;
  background-color: $uranian-blue;
}